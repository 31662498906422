<template>
  <ConfirmDialog />
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '650px' }"
    :header="
      data_cita.id ? 'EDITAR CITA: ' + data_cita.nombre_cliente : 'NUEVA CITA'
    "
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-3">
          <span><strong>CI: </strong></span>
          <InputText
            v-model="ci_cliente"
            placeholder="CI"
            v-tooltip.top="'Carnet de identidad del paciente'"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.charCode == 8"
            @keyup.enter="buscarCliente"
          />
        </div>
        <div class="field col-12 md:col-3">
          <span
            ><strong>A. PATERNO: <span class="p-invalid">*</span></strong></span
          >
          <InputText
            v-model="a_paterno_cliente"
            placeholder="Apellido paterno"
            v-tooltip.top="'Apellido paterno del paciente'"
            @keyup.enter="buscarCliente"
          />
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>A. MATERNO: </strong></span>
          <InputText
            v-model="a_materno_cliente"
            placeholder="Apellido materno"
            v-tooltip.top="'Apellido materno del paciente'"
            @keyup.enter="buscarCliente"
          />
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>NOMBRES: </strong></span>
          <Textarea
            v-model="nombres_cliente"
            rows="1"
            autoResize
            placeholder="Nombres"
            v-tooltip.top="'Nombres del paciente'"
          />
        </div>
        <div class="field col-12 md:col-12">
          <span>
            <strong>SELECCIONAR PACIENTE:</strong>
            <span class="p-invalid">*</span>
          </span>
          <span
            v-if="data_cita.telefono"
            style="
              font-size: 1.2em;
              border: 1px solid #007dd7;
              padding: 5px;
              border-radius: 5px;
              margin-left: 5px;
            "
          >
            <strong>Telf.: </strong>{{ data_cita.telefono }}
          </span>
          <span v-else>-</span>

          <div class="p-inputgroup">
            <Dropdown
              ref="clienteDropdown"
              v-model="data_cita.cliente_id"
              :options="clientes"
              optionLabel="nombre_cliente"
              optionValue="id"
              placeholder="Seleccione un paciente"
              v-tooltip.top="'Seleccione al paciente'"
              :disabled="buscandoCliente"
              :filter="true"
              @change="obtenerTelefonosCliente"
            >
              <template #item="slotProps">
                <div>
                  <strong>{{ slotProps.option.nombre_cliente }}</strong> - CI.:
                  {{ slotProps.option.EMP_Documento }}
                </div>
              </template>
            </Dropdown>
            <Button
              icon="pi pi-search"
              class="p-button-warning p-button-sm"
              v-tooltip.top="'Buscar paciente'"
              @click="buscarCliente"
              :loading="buscandoCliente"
            />
            <Button
              v-show="false"
              icon="pi pi-plus"
              class="p-button-info p-button-sm"
              v-tooltip.top="'Agregar paciente'"
              @click="activarCliente"
            />
          </div>
          <small class="p-invalid" v-if="errors.cliente_id">{{
            errors.cliente_id[0]
          }}</small>
        </div>

        <!-- <div class="field col-12 md:col-12">
          <span
            ><strong>BUSCAR PACIENTE POR: NOMBRE | CI:</strong
            ><span class="p-invalid">*</span></span
          >
          <span
            v-if="data_cita?.telefono || data_cita?.nombre_cliente?.telefono"
            style="
              font-size: 1.2em;
              border: 1px solid #007dd7;
              padding: 5px;
              border-radius: 5px;
              margin-left: 5px;
            "
          >
            <strong>Telf.: </strong
            >{{ data_cita?.telefono || data_cita?.nombre_cliente?.telefono }}
          </span>
          <span v-else>-</span>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-user"></i>
            </span>
            <AutoComplete
              v-model="data_cita.nombre_cliente"
              :suggestions="clientes"
              field="nombre_cliente"
              v-tooltip.top="'Buscar paciente'"
              optionValue="id"
              @keyup.enter="buscarCliente"
              :disabled="buscandoCliente"
            >
              <template #item="slotProps">
                <div>
                  <strong>{{ slotProps.item.nombre_cliente }}</strong
                  ><span><strong> *** </strong></span>CI.:
                  {{ slotProps.item.EMP_Documento }}
                </div>
              </template>
            </AutoComplete>
            <Button
              icon="pi pi-search"
              class="p-button-warning p-button-sm"
              v-tooltip.top="'Buscar paciente'"
              @click="buscarCliente"
              :loading="buscandoCliente"
            />
            <Button
              v-show="false"
              icon="pi pi-plus"
              class="p-button-info p-button-sm"
              v-tooltip.top="'Agregar paciente'"
              @click="activarCliente"
            />
          </div>
          <small class="p-invalid" v-if="errors.cliente_id">{{
            errors.cliente_id[0]
          }}</small>
        </div> -->
        <div class="field col-12 md:col-12">
          <span
            ><strong>BUSCAR MÉDICO POR: NOMBRE | C&Oacute;DIGO:</strong
            ><span class="p-invalid">*</span></span
          >
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-user"></i>
            </span>
            <AutoComplete
              v-model="data_cita.nombre_medico"
              :suggestions="medicos"
              field="nombre_medico"
              v-tooltip.top="'Buscar Médico'"
              optionValue="id"
              @keyup.enter="buscarMedico"
              :disabled="buscandoMedico"
            >
              <template #item="slotProps">
                <div>
                  <strong>{{ slotProps.item.nombre_medico }}</strong
                  ><span><strong> *** </strong></span>COD.:
                  {{ slotProps.item.id }}
                </div>
              </template>
            </AutoComplete>
            <Button
              icon="pi pi-search"
              class="p-button-warning p-button-sm"
              v-tooltip.top="'Buscar Médico'"
              @click="buscarMedico"
              :loading="buscandoMedico"
            />
          </div>
          <small class="p-invalid" v-if="errors.medico_id">{{
            errors.medico_id[0]
          }}</small>
        </div>

        <div class="field col-12 md:col-4">
          <span
            ><strong
              >DURACIÓN DE LA CITA: <span class="p-invalid">*</span></strong
            ></span
          >
          <Dropdown
            v-model="data_cita.duracion"
            :options="duraciones_citas"
            optionLabel="label"
            optionValue="value"
            placeholder="DURACIÓN DE LA CITA"
            v-tooltip.top="'Duración de la cita'"
          >
            <template #option="slotProps">
              <strong>{{ slotProps.option.label }}</strong>
            </template>
          </Dropdown>
          <small class="p-invalid" v-if="errors.duracion">{{
            errors.duracion[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <span
            ><strong
              >FECHA DE LA CITA: <span class="p-invalid">*</span></strong
            ></span
          >
          <Calendar
            v-model="data_cita.fecha"
            dateFormat="dd/mm/yy"
            v-tooltip.top="'Fecha de la cita'"
            :minDate="new Date()"
          />
          <small class="p-invalid" v-if="errors.fecha_inicio">{{
            errors.fecha_inicio[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <span
            ><strong
              >HORA DE LA CITA: <span class="p-invalid">*</span></strong
            ></span
          >
          <InputText
            v-model="data_cita.hora"
            placeholder="Hora de la cita"
            v-tooltip.top="'Hora de la cita'"
            maxlength="5"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.charCode == 8"
          />
          <small class="p-invalid" v-if="errors.hora">{{
            errors.hora[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3" v-show="false">
          <span
            ><strong>SERVICIO:<span class="p-invalid">*</span></strong></span
          >
          <Dropdown
            disabled
            v-model="data_cita.consultorio_id"
            :options="consultorios"
            :filter="true"
            optionLabel="nombre"
            optionValue="id"
            placeholder="Seleccione..."
            v-tooltip.top="'Sevicio'"
            style="text-transform: uppercase; font-size: 1em"
          >
            <template #option="slotProps">
              <span style="text-transform: uppercase; font-size: 1em"
                ><strong>{{ slotProps.option.nombre }}</strong></span
              >
            </template>
          </Dropdown>
          <small class="p-invalid" v-if="errors.consultorio_id">{{
            errors.consultorio_id[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-12">
          <span
            :style="{
              background: '#' + data_cita.color,
              color: 'white',
              padding: '5px',
              borderRadius: '5px',
              textTransform: 'uppercase',
            }"
          >
            <strong>{{ data_cita.nombre_consultorio ?? "" }}</strong></span
          ><span><strong> *** </strong></span
          ><span
            ><strong
              >SELECCIONAR ESTUDIOS A REALIZAR:<span class="p-invalid"
                >*</span
              ></strong
            ></span
          >

          <MultiSelect
            v-model="data_cita.estudios"
            :options="estudiosFiltrados"
            :filter="true"
            optionLabel="nombreEstudio"
            placeholder="Seleccione estudios"
            display="chip"
            v-tooltip.top="'Estudios'"
          />
          <small class="p-invalid" v-if="errors.estudios">{{
            errors.estudios[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4" v-show="false">
          <span><strong>UBICACI&Oacute;N: </strong></span>
          <Dropdown
            v-model="data_cita.ubicacion_id"
            :options="ubicacionesFiltradas"
            optionLabel="nombre"
            optionValue="id"
            placeholder="Seleccione..."
            v-tooltip.top="'Ubicación de la cita'"
          />
        </div>
        <div class="field col-12 md:col-6">
          <span><strong>CONVENIO: </strong></span>
          <Dropdown
            v-model="data_cita.convenio"
            :options="convenios"
            optionLabel="EMP_NombreLargo"
            optionValue="EMP_NombreLargo"
            placeholder="Seleccione..."
            v-tooltip.top="'Convenio'"
            :filter="true"
          />
        </div>
        <div class="field col-12 md:col-4" v-show="false">
          <span><strong>COLOR:</strong></span>
          <ColorPicker v-model="data_cita.color" disabled />
        </div>
        <div class="field col-12 md:col-6">
          <span><strong>OBSERVACI&Oacute;N: </strong></span>
          <Textarea
            v-model="data_cita.detalle"
            rows="1"
            autoResize
            placeholder="Observación de la cita"
            v-tooltip.top="'Observación de la cita'"
          />
          <small class="p-invalid" v-if="errors.detalle">{{
            errors.detalle[0]
          }}</small>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="grid">
        <strong>CAMPOS REQUERIDOS: </strong>
        <span class="p-invalid">*</span>
      </div>
      <div class="grid">
        <div class="field col-12 md:col-4 flex md:justify-content-start">
          <Button
            v-if="data_cita.id && data_cita.estado == 1"
            icon="pi pi-trash"
            class="p-button-danger p-button-sm"
            @click="eliminarCita"
            v-tooltip.top="'Eliminar cita'"
            :loading="eliminando"
          />
          <Button
            v-if="data_cita.estado != 2 && data_cita.id"
            icon="pi pi-calendar"
            class="p-button-secondary p-button-sm"
            v-tooltip.top="'Reprogramar cita'"
            @click="reprogramarCita"
            :loading="enviando"
          />
          <Button
            v-if="
              data_cita.estado == 2 &&
              'Agenda ActivarCita' in auth.user.permissions
            "
            label="ACTIVAR CITA"
            icon="pi pi-check"
            class="p-button-info p-button-sm"
            v-tooltip.top="'Activar cita'"
            @click="activarCita"
          />
        </div>
        <div class="field col-12 md:col-8">
          <Button
            label="CANCELAR"
            icon="pi pi-times"
            class="p-button-success"
            @click="ocultarDialog"
          />

          <Button
            v-if="data_cita.estado == 1"
            label="ATENDIDO"
            icon="pi pi-check"
            class="p-button-warning"
            @click="atenderCita"
            v-tooltip.top="'Marcar como atendido'"
            :loading="atendiendo"
          />
          <Button
            v-if="data_cita.estado != 2"
            :disabled="
              !data_cita.cliente_id ||
              !data_cita.nombre_medico ||
              !data_cita.estudios.length > 0
            "
            :label="data_cita.id ? 'ACTUALIZAR' : 'GUARDAR'"
            :icon="data_cita.id ? 'pi pi-pencil' : 'pi pi-save'"
            class="p-button-info"
            @click="guardarCita"
            v-tooltip.top="data_cita.id ? 'Actualizar cita' : 'Guardar cita'"
            :loading="enviando"
          />
        </div>
      </div>
    </template>
  </Dialog>
  <ClienteCreate
    :show="clienteDialog"
    :cliente="cliente"
    @actualizarListado="obtenerClientes"
    @closeModal="cerrarClienteDialog"
  />
</template>

<script>
import AgendaService from "@/service/AgendaService";
import ClienteCreate from "@/module/clientes/ClienteCreate.vue";
import ClienteService from "@/service/ClienteService";
import ColorPicker from "primevue/colorpicker";
import MedicoService from "@/service/MedicoService";
import { useAuth } from "@/stores";

export default {
  components: {
    ClienteCreate,
    ColorPicker,
  },
  emits: ["actualizarListado", "closeModal"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    cita: {
      type: Object,
      default: () => {
        return {
          fecha: "",
          hora: "",
        };
      },
    },
    consultorios: {
      type: Array,
      default: () => [],
    },

    medicoLogueado: {
      type: Boolean,
      default: true,
    },
    convenios: {
      type: Array,
      default: () => [],
    },
    ubicacionesFiltradas: {
      type: Array,
      default: () => [],
    },
    estudiosFiltrados: {
      type: Array,
      default: () => [],
    },
    pre_registro_id: {
      type: Number,
      default: null,
    },
  },

  /* computed: {
    telefono() {
      let telefono = "";
      console.log(this.data_cita);
      if (this.data_cita?.telefono) {
        telefono = this.data_cita.telefono;
      } else if (this.data_cita?.nombre_cliente?.telefono) {
        telefono = this.data_cita.nombre_cliente.telefono;
      }

      return telefono;
    },
  }, */
  data() {
    return {
      errors: {},
      mostrarModal: this.show,
      enviando: false,
      data_cita: this.cita,
      clientes: [],
      clienteDialog: false,
      cliente: {
        estado: { label: "Activo", value: 1 },
      },
      existe_medico_logueado: this.medicoLogueado,
      duraciones_citas: [
        { label: "10 minutos", value: 10 },
        { label: "15 minutos", value: 15 },
        { label: "20 minutos", value: 20 },
        { label: "25 minutos", value: 25 },
        { label: "30 minutos", value: 30 },
        { label: "40 minutos", value: 40 },
        { label: "1 hora", value: 60 },
        { label: "1 hora 30 minutos", value: 90 },
        { label: "2 horas", value: 120 },
        { label: "4 horas", value: 240 },
        { label: "6 horas", value: 360 },
      ],
      eliminando: false,
      atendiendo: false,
      medicos: [],
      reprogramar_cita: false,
      buscandoCliente: false,
      buscandoMedico: false,
      ci_cliente: null,
      a_paterno_cliente: null,
      a_materno_cliente: null,
      nombres_cliente: null,
    };
  },
  agendaService: null,
  auth: null,
  clienteService: null,
  medicoService: null,
  created() {
    this.agendaService = new AgendaService();
    this.clienteService = new ClienteService();
    this.medicoService = new MedicoService();
    this.auth = useAuth();
  },
  methods: {
    obtenerTelefonosCliente() {
      if (this.data_cita.cliente_id) {
        const cliente = this.clientes.find(
          (cliente) => cliente.id == this.data_cita.cliente_id
        );
        if (cliente) {
          this.data_cita.telefono = cliente.telefono;
        } else {
          console.error("Cliente no encontrado en la lista.");
        }
      }
    },

    activarCita() {
      let datos = {
        id: this.data_cita.id,
        color: this.data_cita.color ? this.data_cita.color : "#007dd7",
        consultorio_id: this.data_cita.consultorio_id,
      };
      this.agendaService.activarCita(datos).then((data) => {
        if (data.success) {
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: data.message,
            life: 10000,
          });
          this.$emit("actualizarListado");
          this.ocultarDialog();
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: data.message,
            life: 10000,
          });
        }
      });
    },
    reprogramarCita() {
      this.reprogramar_cita = true;
      this.guardarCita();
    },
    atenderCita() {
      let datos = {
        id: this.data_cita.id,
        nombre_cliente: this.data_cita.nombre_cliente,
        cliente_id: this.data_cita.cliente_id,
      };
      this.$router.push({
        name: "plantratamientos",
        params: {
          nombre_cliente: datos.nombre_cliente,
          cliente_id: datos.cliente_id,
        },
      });
      this.atendiendo = true;
      this.agendaService.updateEstadoAgenda(datos).then((data) => {
        if (data.success) {
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: data.message,
            life: 800,
          });
          this.atendiendo = false;
          this.$emit("actualizarListado");
          this.ocultarDialog();
        } else {
          this.atendiendo = false;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: data.message,
            life: 800,
          });
        }
      });
    },
    buscarCliente() {
      let datos = {
        ci_cliente: this.ci_cliente,
        a_paterno_cliente: this.a_paterno_cliente,
        a_materno_cliente: this.a_materno_cliente,
        nombres_cliente: this.nombres_cliente,
      };
      this.buscandoCliente = true;
      this.clienteService.buscarXNombreOtraBD(datos).then((response) => {
        if (response.status === 400 || response.clientes.length === 0) {
          this.$toast.add({
            severity: "error",
            summary: "ERROR",
            detail: "No se encontró el cliente",
            life: 9000,
          });
          this.buscandoCliente = false;
          return;
        }
        this.clientes = [...response.clientes];
        if (this.clientes.length === 1) {
          // Asignar automáticamente el cliente si solo hay uno
          this.data_cita.cliente_id = this.clientes[0].id;
        } else {
          // Accedo a clienteDropdown y listo
          this.$nextTick(() => {
            this.$refs.clienteDropdown.show();
          });
        }
        this.buscandoCliente = false;
      });
    },

    buscarMedico() {
      let datos = {
        texto: this.data_cita.nombre_medico,
      };
      this.buscandoMedico = true;
      this.medicoService.buscarMedicoXNombreOtraBD(datos).then((response) => {
        if (response.status == 400 || response.medicos.length == 0) {
          this.$toast.add({
            severity: "error",
            summary: "ERROR",
            detail: "No se encontró el médico",
            life: 30000,
          });
          this.buscandoMedico = false;
          return;
        }
        this.medicos = [...response.medicos];
        this.buscandoMedico = false;
      });
    },
    activarCliente() {
      this.clienteDialog = true;
      this.cliente = {
        estado: { label: "Activo", value: 1 },
        expedido: { label: "-", value: "-" },
        nombre: this.data_cita.nombre_cliente ?? "",
      };
    },
    cerrarClienteDialog() {
      this.clienteDialog = false;
    },
    obtenerClientes(clienteArray = null) {
      // Asegúrate de que clienteArray no sea null y sea un array con al menos un elemento
      if (clienteArray && clienteArray.length > 0) {
        // Accede al primer elemento del array
        const cliente = clienteArray[0];

        // Asigna los valores a los datos de la cita
        this.data_cita.cliente_id = cliente.id;
        this.data_cita.nombre_cliente = cliente.nombre_cliente;
        this.data_cita.telefono = cliente.telefono;
        this.clientes = [];
      } else {
        // Maneja el caso donde no se recibe un cliente válido
        this.data_cita.cliente_id = null;
        this.data_cita.nombre_cliente = "";
        this.clientes = [];
      }
    },

    dateFormatted(date) {
      //que la primera letra sea mayuscula Lunes 01 de Enero de 2021 a las 10:00 hrs
      let fecha = new Date(date);
      let options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      let fecha_format = fecha.toLocaleDateString("es-ES", options);

      return fecha_format.charAt(0).toUpperCase() + fecha_format.slice(1);
    },
    ocultarDialog() {
      this.$emit("closeModal");
      this.mostrarModal = false;
      this.data_cita = {
        fecha: "",
        hora: "",
        duracion: 30,
        detalle: "",
        cliente_id: null,
        nombre_cliente: "",
        medico_id: null,
        nombre_medico: "",
        consultorio_id: null,
        nombre_consultorio: "",
        color: null,
      };
      this.errors = {};
      this.enviando = false;
      this.eliminando = false;
      this.atendiendo = false;
      this.reprogramar_cita = false;
      this.buscandoCliente = false;
      this.buscandoMedico = false;
      this.ci_cliente = null;
      this.a_paterno_cliente = null;
      this.a_materno_cliente = null;
      this.nombres_cliente = null;
    },
    validarFormulario() {
      this.errors = {};
      if (
        !this.data_cita.nombre_cliente ||
        !this.data_cita.nombre_cliente != ""
      ) {
        this.errors.nombre = ["El campo nombre es requerido"];
      }
      if (!this.data_cita.medico_id) {
        this.errors.medico_id = ["El campo médico es requerido"];
      }
      if (!this.data_cita.consultorio_id) {
        this.errors.consultorio_id = ["El campo consultorio es requerido"];
      }
      if (!this.data_cita.fecha) {
        this.errors.fecha = ["El campo fecha es requerido"];
      }
      if (!this.data_cita.hora) {
        this.errors.hora = ["El campo hora es requerido"];
      }
      if (!this.data_cita.duracion) {
        this.errors.duracion = ["El campo duración es requerido"];
      }
      return Object.keys(this.errors).length === 0;
    },
    guardarCita() {
      this.errors = {};
      this.enviando = true;
      if (!this.data_cita.fecha) {
        this.errors.fecha_inicio = ["El campo fecha es requerido"];
        this.enviando = false;
        return;
      }
      if (!this.data_cita.hora) {
        this.errors.hora = ["El campo hora es requerido"];
        this.enviando = false;
        return;
      }
      /* if (!this.validarFormulario()) {
        this.enviando = false;
        return;
      } */

      if (this.data_cita.id) {
        let datos = {
          ...this.data_cita,
          cliente_id: this.data_cita.cliente_id || null,
          medico_id: this.data_cita.medico_id,
          consultorio_id: this.data_cita.consultorio_id || null,
          nombre_consultorio: this.data_cita.consultorio_id
            ? this.consultorios.find(
                (consultorio) => consultorio.id == this.data_cita.consultorio_id
              ).nombre
            : "",
          /* nombre_cliente:
            this.data_cita.nombre_cliente.nombre_cliente ??
            this.data_cita.nombre_cliente, */
          nombre_cliente: this.data_cita.cliente_id
            ? this.clientes.find(
                (cliente) => cliente.id == this.data_cita.cliente_id
              ).nombre_cliente
            : this.data_cita.nombre_cliente,
          nombre_medico:
            this.data_cita.nombre_medico.nombre_medico ??
            this.data_cita.nombre_medico,
          fecha_inicio: `${this.data_cita.fecha} ${this.data_cita.hora}`,
          duracion: this.data_cita.duracion,
          color: this.data_cita.color ? this.data_cita.color : "#007dd7",
          /* color: this.data_cita.color ? this.data_cita.color : "#007dd7", */
          /*  telefono:
            this.data_cita.nombre_cliente.telefono == undefined
              ? this.data_cita.telefono
              : this.data_cita.nombre_cliente.telefono, */
          telefono: this.data_cita.cliente_id
            ? this.clientes.find(
                (cliente) => cliente.id == this.data_cita.cliente_id
              ).telefono
            : this.data_cita.telefono,
          ubicacion_id: this.data_cita.ubicacion_id,
          nombre_ubicacion: this.data_cita.ubicacion_id
            ? this.ubicacionesFiltradas.find(
                (ubicacion) => ubicacion.id == this.data_cita.ubicacion_id
              ).nombre
            : "",
          reprogramar_cita: this.reprogramar_cita,
        };
        this.agendaService.updateAgenda(datos).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
            this.enviando = false;
          } else {
            if (data.success) {
              this.$toast.add({
                severity: "success",
                summary: "Éxito",
                detail: data.message,
                life: 30000,
              });
              this.enviando = false;
              this.$emit("actualizarListado");
              this.ocultarDialog();
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: data.message,
                life: 30000,
              });
              this.enviando = false;
            }
          }
        });
        return;
      } else {
        let datos = {
          ...this.data_cita,
          /* cliente_id:
            this.data_cita.nombre_cliente.id == undefined
              ? this.data_cita.cliente_id
              : this.data_cita.nombre_cliente.id, */
          cliente_id: this.data_cita.cliente_id || null,
          medico_id: this.data_cita.nombre_medico.id,
          consultorio_id: this.data_cita.consultorio_id || null,
          nombre_consultorio: this.data_cita.consultorio_id
            ? this.consultorios.find(
                (consultorio) => consultorio.id == this.data_cita.consultorio_id
              ).nombre
            : "",
          /* nombre_cliente:
            this.data_cita.nombre_cliente.nombre_cliente == undefined
              ? this.data_cita.nombre_cliente
              : this.data_cita.nombre_cliente.nombre_cliente, */
          nombre_cliente: this.data_cita.cliente_id
            ? this.clientes.find(
                (cliente) => cliente.id == this.data_cita.cliente_id
              ).nombre_cliente
            : this.data_cita.nombre_cliente,

          telefono: this.data_cita.cliente_id
            ? this.clientes.find(
                (cliente) => cliente.id == this.data_cita.cliente_id
              ).telefono
            : this.data_cita.telefono,
          nombre_medico: this.data_cita.nombre_medico.nombre_medico,
          hora: this.data_cita.hora,
          fecha_inicio: `${this.data_cita.fecha} ${this.data_cita.hora}`,
          duracion: this.data_cita.duracion,
          color: this.data_cita.color ? this.data_cita.color : "#007dd7",
          ubicacion_id: this.data_cita.ubicacion_id,
          nombre_ubicacion: this.data_cita.ubicacion_id
            ? this.ubicacionesFiltradas.find(
                (ubicacion) => ubicacion.id == this.data_cita.ubicacion_id
              ).nombre
            : "",
          pre_registro_id: this.pre_registro_id ? this.pre_registro_id : null,
        };
        this.agendaService.saveAgenda(datos).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
            this.enviando = false;
          } else {
            if (data.success == false) {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: data.message,
                life: 10000,
              });
              this.enviando = false;
            } else {
              this.$toast.add({
                severity: "success",
                summary: "Éxito",
                detail: data.message,
                life: 30000,
              });
              this.enviando = false;
              this.$emit("actualizarListado");
              this.ocultarDialog();
            }
          }
        });
      }
    },
    eliminarCita() {
      this.$confirm.require({
        message: "¿Está seguro de eliminar la cita?",
        header: "Confirmación",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.eliminando = true;
          this.agendaService.deleteAgenda(this.data_cita.id).then((data) => {
            if (data.success) {
              this.$toast.add({
                severity: "success",
                summary: "Éxito",
                detail: data.message,
                life: 10000,
              });
              this.eliminando = false;
              this.$emit("actualizarListado");
              this.ocultarDialog();
            } else {
              this.eliminando = false;
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: data.message,
                life: 30000,
              });
            }
          });
        },
      });
    },
  },
  watch: {
    show(newVal) {
      this.mostrarModal = newVal;
    },
    cita(newVal) {
      this.data_cita = newVal;
      //CARGO AL DROPDOWN DE CLIENTES
      if (this.data_cita.cliente_id) {
        this.clientes = [
          {
            id: this.data_cita.cliente_id,
            cliente_id: this.data_cita.cliente_id,
            nombre_cliente: this.data_cita.nombre_cliente,
            telefono: this.data_cita.telefono,
          },
        ];
      }
    },
    medicoLogueado(newVal) {
      this.existe_medico_logueado = newVal;
    },
    "data_cita.cliente_id": {
      handler(newVal) {
        if (newVal) {
          const cliente = this.clientes.find((cliente) => cliente.id == newVal);
          if (cliente) {
            this.data_cita.nombre_cliente = cliente.nombre_cliente;
            this.data_cita.telefono = cliente.telefono;
          } else {
            console.error("Cliente no encontrado en la lista.");
          }
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
  font-weight: bold;
  font-size: 1em;
}
</style>
