import { fetchWrapper } from "@/helpers";
const ruta = process.env.VUE_APP_ROOT_API;

export default class AgendaService {
  async obtenerListadoAgenda(data) {
    return await fetchWrapper.post(`${ruta}/agendas/listado`, data);
  }

  async saveAgenda(datos) {
    const nueva_cita = await fetchWrapper.post(`${ruta}/agendas/crear`, datos);
    return nueva_cita;
  }

  async sendClienteNuevo(cliente) {
    const nuevoCliente = await fetchWrapper.post(`${ruta}/cliente`, cliente);
    return nuevoCliente;
  }

  async updateAgenda(agenda) {
    return await fetchWrapper.put(`${ruta}/agendas/actualizar`, agenda);
  }

  async deleteAgenda(id) {
    return await fetchWrapper.delete(`${ruta}/agendas/eliminar/${id}`);
  }

  async updateEstadoAgenda(id) {
    return await fetchWrapper.put(`${ruta}/agendas/actualizar_estado`, id);
  }

  async buscarAgendas(data) {
    return await fetchWrapper.post(`${ruta}/agendas/reporte`, data);
  }

  async enviarWhatsapp(data) {
    return await fetchWrapper.post(`${ruta}/agendas/enviarWhatsapp`, data);
  }

  async updateRecordatorio(data) {
    return await fetchWrapper.post(`${ruta}/agendas/recordatorio`, data);
  }

  async exportarAgendas(data) {
    return await fetchWrapper.postWithBlob(`${ruta}/agendas/exportar`, data);
  }

  async activarCita(data) {
    return await fetchWrapper.post(`${ruta}/agendas/activar`, data);
  }
}
