<template>
  <Toast />
  <div class="surface-section px-3 py-4 md:px-12 lg:px-12 estilo-superficies">
    <div class="grid">
      <div class="field col-12 md:col-2 mt-4">
        <span class="especialidades-title">SERVICIOS</span>

        <!-- Planta Baja -->
        <div class="planta-baja mt-4">
          <span class="planta-title">Planta Baja</span>
          <ul class="list-none p-0 m-0 text-center">
            <li
              v-for="(consultorio, index) in consultoriosPlantaBaja"
              :key="index"
              class="consultorio-item"
            >
              <span
                @click="
                  mostrarAgenda(
                    consultorio.id,
                    consultorio.text_color,
                    consultorio.ubicaciones,
                    consultorio.codigoTipoEstudio
                  )
                "
                :class="{
                  'badge-primary': consultorio.id === selectedConsultorioId,
                  'badge-secondary': consultorio.id !== selectedConsultorioId,
                }"
                class="consultorio-name"
              >
                {{ consultorio.nombre }}
              </span>
            </li>
          </ul>
        </div>

        <!-- Planta Alta -->
        <div class="planta-alta mt-4">
          <span class="planta-title">Planta Alta</span>
          <ul class="list-none p-0 m-0 text-center">
            <li
              v-for="(consultorio, index) in consultoriosPlantaAlta"
              :key="index"
              class="consultorio-item"
            >
              <span
                @click="
                  mostrarAgenda(
                    consultorio.id,
                    consultorio.text_color,
                    consultorio.ubicaciones,
                    consultorio.codigoTipoEstudio
                  )
                "
                :class="{
                  'badge-primary': consultorio.id === selectedConsultorioId,
                  'badge-secondary': consultorio.id !== selectedConsultorioId,
                }"
                class="consultorio-name"
              >
                {{ consultorio.nombre }}
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div class="field col-12 md:col-10">
        <FullCalendar
          :options="calendarOptions"
          :events="events"
          style="font-size: 12px"
        />
        <NuevaCita
          :show="nuevaCitaDialog"
          :cita="cita"
          :consultorios="consultorios"
          :medicoLogueado="medicoLogueado"
          :convenios="convenios"
          :ubicacionesFiltradas="ubicacionesFiltradas"
          :estudiosFiltrados="estudiosFiltrados"
          :pre_registro_id="pre_registro_id"
          @closeModal="ocultarDialog()"
          @actualizarListado="obtenerListadoAgenda()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es";
import NuevaCita from "@/module/agenda/NuevaCita.vue";
import AgendaService from "@/service/AgendaService";
import MedicoService from "@/service/MedicoService";
import { useAuth } from "@/stores";

export default {
  emits: ["cerrarModalPreregistro", "actualizarListadoPreregistro"],
  props: {
    mostrar_agenda: {
      type: Boolean,
      default: false,
    },
    pre_registro_id: {
      type: Number,
      default: null,
    },
  },
  components: {
    FullCalendar,
    NuevaCita,
  },
  data() {
    return {
      data_mostrarAgenda: this.mostrar_agenda,
      nuevaCitaDialog: false,
      cita: {
        id: null,
        medico_id: null,
        nombre_medico: null,
        consultorio_id: null,
        nombre_consultorio: null,
        cliente_id: null,
        nombre_cliente: null,
        ubicacion_id: null,
        ubicacion: null,
        convenio: 0,
        detalle: null,
        fecha_inicio: null,
        fecha_fin: null,
        duracion: null,
        color: null,
        textColor: null,
        estado: null,
        estudios: [],
        codigos_estudios: [],
        nombres_estudios: [],
      },
      agendas: [],
      loading: false,
      events: [],
      consultorios: [],
      medicos: [],
      medicoLogueado: false,
      medicoSelected: 0,
      convenios: [],
      agendasFiltradas: [],
      selectedConsultorioId: null,
      colorConsultorio: "#007dd7",
      especialidades_asignadas: [],
      consultoriosPlantaBaja: [],
      consultoriosPlantaAlta: [],
      ubicaciones: [],
      ubicacionesFiltradas: [],
      estudios: [],
      estudiosFiltrados: [],
      codigoTipoEstudio: null,
      lastParam: null,
      lastFechaInicio: null,
      lastFechaFin: null,
    };
  },
  agendaService: null,
  medicoService: null,
  auth: null,

  created() {
    this.agendaService = new AgendaService();
    this.medicoService = new MedicoService();
    this.auth = useAuth();
  },
  mounted() {
    //this.cargarMedicos();
    this.obtenerListadoAgenda();
  },
  computed: {
    calendarOptions() {
      return {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: "timeGridDay", // Puedes cambiar la vista inicial según tu preferencia
        locales: [esLocale],
        buttonText: {
          today: "Hoy",
          week: "Semana",
          day: "Día",
        },

        //mostrar indicador de tiempo actual
        nowIndicator: true,

        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        // Limitar los horarios y mostrar am o pm
        slotMinTime: "00:00:00",
        slotDuration: "00:10:00",
        slotMaxTime: "24:00:00",
        slotLabelInterval: "00:10:00",
        slotLabelFormat: {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        },

        //height: "auto",
        allDaySlot: false,
        selectable: true,
        selectMirror: true,
        dateClick: this.agregarCita,
        eventClick: this.agregarCita,

        slotLabelContent: function (arg) {
          const startHour = arg.date.getHours().toString().padStart(2, "0");
          const startMinute = arg.date.getMinutes().toString().padStart(2, "0");

          const endDate = new Date(arg.date.getTime() + 10 * 60000); // Sumar 10 minutos
          const endHour = endDate.getHours().toString().padStart(2, "0");
          const endMinute = endDate.getMinutes().toString().padStart(2, "0");

          return `${startHour}:${startMinute} - ${endHour}:${endMinute}`;
        },

        events: this.agendasFiltradas.map((agenda) => {
          return {
            id: agenda.id,
            title: `${
              agenda.nombre_cliente ? agenda.nombre_cliente + " - " : ""
            }
            ${agenda.nombre_medico ? agenda.nombre_medico + " - " : ""}
            ${
              agenda.nombre_consultorio ? agenda.nombre_consultorio + " - " : ""
            }
            ${agenda.detalle ? agenda.detalle + " - " : ""}
            ${agenda.nombres_estudios ? agenda.nombres_estudios : ""}`,
            start: agenda.fecha_inicio,
            end: agenda.fecha_fin,
            backgroundColor: agenda.color,
            textColor: agenda.text_color,
            extendedProps: {
              detalle: agenda.detalle,
              cliente_id: agenda.cliente_id,
              telefono: agenda.telefono,
              medico_id: agenda.medico_id,
              consultorio_id: agenda.consultorio_id,
              estado: agenda.estado,
              convenio: agenda.convenio,
              ubicacion_id: agenda.ubicacion_id,
              estudios: agenda.codigos_estudios || [],
              nombres_estudios: agenda.nombres_estudios || [],
            },
          };
        }),
        eventDidMount: function (info) {
          let view = info.view.type;
          if (view === "timeGridDay") {
            // si es vista diaria
            info.el.style.fontSize = "12px";
          } else if (view === "timeGridWeek") {
            // si es vista semanal
            info.el.style.fontSize = "10px";
          } else if (view === "dayGridMonth") {
            // si es vista mensual
            info.el.style.fontSize = "9px";
          }
          //que se al centro el texto y se vea completo
          info.el.style.fontWeight = "bold";
        },

        //evento que caputa cambios en la navegación
        //datesSet: this.handleDatesSet,
      };
    },
  },
  methods: {
    handleDatesSet(info) {
      let parametro;

      switch (info.view.type) {
        case "dayGridMonth":
          parametro = "mes";
          break;
        case "timeGridWeek":
          parametro = "semana";
          break;
        case "timeGridDay":
          parametro = "dia";
          break;
        default:
          parametro = "otro";
          break;
      }

      let param = parametro === "otro" ? "dia" : parametro;
      let fechaInicio = info.startStr ? info.startStr : info.start;
      let fechaFin = info.endStr ? info.endStr : info.end;

      // Verificar si los parámetros son diferentes
      if (
        param !== this.lastParam ||
        fechaInicio !== this.lastFechaInicio ||
        fechaFin !== this.lastFechaFin
      ) {
        // Guardar los nuevos valores
        this.lastParam = param;
        this.lastFechaInicio = fechaInicio;
        this.lastFechaFin = fechaFin;

        // Llamar a la función solo si hay cambios
        this.obtenerListadoAgenda(param, fechaInicio, fechaFin);
      }
    },

    mostrarAgenda(consultorio_id, color, ubicaciones, codigoTipoEstudio) {
      this.selectedConsultorioId = consultorio_id;
      this.colorConsultorio = color || "#007dd7";
      this.ubicacionesFiltradas = ubicaciones || [];
      this.estudiosFiltrados = [];
      this.codigoTipoEstudio = codigoTipoEstudio || null;
      if (this.codigoTipoEstudio) {
        this.filtrarEstudios(codigoTipoEstudio);
      }
      this.agendasFiltradas =
        consultorio_id === 0
          ? this.agendas
          : this.agendas.filter(
              (agenda) => agenda.consultorio_id === consultorio_id
            );
    },

    filtrarEstudios(codigoTipoEstudio) {
      this.estudiosFiltrados = this.estudios.filter(
        (estudio) => parseInt(estudio.codigoTipoEstudio) === codigoTipoEstudio
      );
    },

    obtenerListadoAgenda(param, fechaInicio, fechaFin) {
      try {
        this.loading = true;
        let data = {
          param: param ? param : "dia",
          fechaInicio: fechaInicio ? fechaInicio : null,
          fechaFin: fechaFin ? fechaFin : null,
        };
        this.agendaService.obtenerListadoAgenda(data).then((data) => {
          this.especialidades_asignadas = data.especialidades_asignadas || [];
          this.ubicaciones = data.ubicaciones || [];
          this.agendas = data.agendas || [];
          let consultorios = data.consultorios || [];

          // Agregar opción "TODOS"
          consultorios.unshift({ id: 0, nombre: "TODOS", direccion: "TODOS" });

          // Filtrar consultorios basados en especialidades asignadas
          if (this.especialidades_asignadas.length > 0) {
            consultorios = consultorios.filter((consultorio) =>
              this.especialidades_asignadas.some(
                (especialidad) => especialidad.id === consultorio.id
              )
            );
          }

          // Separar consultorios por planta después de filtrar
          this.consultorios = consultorios;
          this.consultoriosPlantaBaja = this.consultorios.filter(
            (consultorio) =>
              consultorio.direccion === "Planta Baja" ||
              consultorio.direccion === "TODOS"
          );
          this.consultoriosPlantaAlta = this.consultorios.filter(
            (consultorio) =>
              consultorio.direccion === "Planta Alta" ||
              consultorio.direccion === "TODOS"
          );

          // Seleccionar automáticamente el primer consultorio permitido
          if (this.consultorios.length > 0) {
            this.selectedConsultorioId =
              this.selectedConsultorioId || this.consultorios[0].id;
            this.colorConsultorio =
              this.colorConsultorio || this.consultorios[0].text_color;
          }

          this.convenios = data.convenios || [];
          this.convenios.length > 0
            ? this.convenios.unshift({
                EMP_NombreLargo: "PARTICULAR",
                value: 0,
              })
            : null;
          this.estudios = data.estudios || [];

          this.loading = false;
          this.mostrarAgenda(
            this.selectedConsultorioId,
            this.colorConsultorio,
            this.ubicacionesFiltradas,
            this.codigoTipoEstudio
          );
        });
      } catch (error) {
        console.log(error);
      }
    },

    ocultarDialog() {
      this.nuevaCitaDialog = false;
      if (this.pre_registro_id) {
        this.$emit("cerrarModalPreregistro");
        this.$emit("actualizarListadoPreregistro");
      }
    },
    agregarCita(info) {
      // Verifico si hay un consultorio seleccionado
      if (!this.selectedConsultorioId) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Seleccione una especialidad para agregar una cita",
          life: 3000,
        });
        return;
      }
      //verifico si es un evento existente
      if (info.event) {
        this.editarCita(info.event);
        return;
      }
      // Verifico que le dio click en lo correcto
      if (!info.dateStr) {
        return;
      }
      //verifo si esta correcto para transformar la fecha
      if (info.dateStr.split("T").length < 2) {
        return;
      }

      // verifico que no sea una hora pasada y que no sea un día anterior
      if (new Date(info.dateStr) < new Date()) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "No se pueden agregar citas en horas pasadas",
          life: 3000,
        });
        return;
      }

      const fecha = info.dateStr.split("T")[0].split("-").reverse().join("/");
      const hora = info.dateStr.split("T")[1].substring(0, 5);
      const duracion = 30; // Duración de la cita en minutos
      const color = this.colorConsultorio;
      const medico_id = this.medicoSelected;
      const consultorio_id = this.selectedConsultorioId;
      const convenio = "PARTICULAR";
      const nombre_consultorio = this.consultorios.find(
        (consultorio) => consultorio.id === consultorio_id
      ).nombre;
      const ubicacion_id =
        this.ubicacionesFiltradas.length > 0
          ? this.ubicacionesFiltradas[0].id
          : null;
      this.cita = {
        fecha,
        hora,
        duracion,
        color,
        medico_id,
        consultorio_id,
        convenio,
        ubicacion_id,
        estudios: [],
        nombre_consultorio,
      };

      this.nuevaCitaDialog = true;
    },
    editarCita(event) {
      if (!event) return;
      const extendedProps = event.extendedProps || {};

      let estudiosActuales = [];

      if (extendedProps.estudios) {
        //QUE INCLUYA LO QUE YA TIENE
        estudiosActuales = this.estudiosFiltrados.filter((estudio) =>
          extendedProps.estudios.includes(
            estudio.codigoEstudio,
            estudio.nombreEstudio
          )
        );
      }

      this.cita = {
        id: event.id,
        fecha: event.startStr.split("T")[0].split("-").reverse().join("/"),
        hora: event.startStr.split("T")[1].substring(0, 5),
        duracion: (new Date(event.end) - new Date(event.start)) / 60000,
        textColor: extendedProps.textColor,
        color: this.colorConsultorio,
        /* color: event.backgroundColor, */
        detalle: extendedProps.detalle || "",
        cliente_id: extendedProps.cliente_id || null,
        nombre_cliente: event.title.split(" - ")[0],
        telefono: extendedProps.telefono || null,
        convenio: extendedProps.convenio || null,
        medico_id: extendedProps.medico_id || null,
        nombre_medico: event.title.split(" - ")[1],
        consultorio_id: extendedProps.consultorio_id || null,
        nombre_consultorio: event.title.split(" - ")[2] || "",
        estado: extendedProps.estado || null,
        ubicacion_id: extendedProps.ubicacion_id || null,
        ubicacion: extendedProps.nombre_ubicacion || "",
        estudios: estudiosActuales,
      };
      this.nuevaCitaDialog = true;
    },
    cargarMedicos() {
      this.medicoService.buscarMedicoXNombreOtraBD().then((data) => {
        this.medicos = data.medicos || [];
        if (this.medicos.length > 0) {
          let medico = this.medicos.find(
            (medico) => medico.nombre_completo === this.auth.user.name
          );
          if (medico) {
            this.medicoSelected = medico.id;
            this.medicoLogueado = true;
          } else {
            this.medicoSelected = null;
          }
        }
      });
    },
  },
  watch: {
    mostrar_agenda(value) {
      this.data_mostrarAgenda = value;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

//bordes al ul y li
ul {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
}

// cuando esté seleccionado color de fondo naranja
.badge-primary {
  background-color: #ff9800;
  color: white;
  border-radius: 5px;
  padding: 2px 5px;
}

.badge-secondary {
  background-color: transparent;
  color: inherit;
}
.especialidades-title {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  display: block;
  margin-bottom: 10px;
  color: #333;
}

.planta-title {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  display: block;
  margin-bottom: 5px;
  color: #555;
}

.consultorio-item {
  list-style: none;
  margin: 5px 0;
}

.consultorio-name {
  display: flex;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 5px;
  border: 2px solid #ddd; /* Borde completo más grueso */
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

/* Opciones de estilo para las clases 'badge-primary' y 'badge-secondary' */
.badge-primary {
  background-color: #ff8000;
  color: white;
}

.badge-secondary {
  background-color: #f8f9fa;
  color: #333;
}
.estilo-superficies {
  border: 1px solid #8f8f8f;
  border-radius: 5px;
  background-color: #f8f9fa;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
</style>
